import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PageImage from '../../components/PageImage';

// import * as S from './styled';

import BlogRoll from '../BlogRoll';
import WrapperPage from '../../components/WrapperPage';

const PostList = ({ posts }) => (
  <WrapperPage id="posts">
    <PageImage>
      <StaticImage
        src="../../assets/images/http.jpg"
        alt="Blog"
        style={{ width: '100%', height: '100%', opacity: 0.2 }}
        layout="fullWidth"
        objectPosition="center"
        quality={85}
        imgStyle={{ filter: 'grayscale()' }}
      />
    </PageImage>
    <BlogRoll posts={posts} />
  </WrapperPage>
);

export default PostList;
