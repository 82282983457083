import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import PostList from '../../templates/PostsList';

const BlogIndexPage = ({ data }) => {
  const { nodes: posts } = data.allFile;
  const postsWithBlogSlug = posts.map(({ childMdx: post }) => ({
    ...post,
    fields: {
      ...post.fields,
      slug: `/blog${post.fields.slug}`,
    },
  }));
  return (
    <Layout>
      <PostList posts={postsWithBlogSlug} />
    </Layout>
  );
};

export default BlogIndexPage;

export const Head = () => <SEO title="Blog - Giedeé Informatyka" />;

export const query = graphql`
  query ($locale: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
    ) {
      nodes {
        childMdx {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            # slug
            title
            featuredimage {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    quality: 90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            date(formatString: "DD MMMM YYYY", locale: $locale)
          }
        }
      }
    }
  }
`;
